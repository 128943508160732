<template>
  <!-- страница все звонки -->
    <PageHeader :title="title" />

    <div class="col-sm-auto" style="margin-bottom:15px;" v-if="this.checks['001'] == true">
      <button id="info" @click="infoblockShow = !infoblockShow" type="button" :class="`btn btn-${infoblockShow == true ? 'primary' : 'info'} waves-effect waves-light`"><i :class="`${infoblockShow == true ? 'bx bxs-hide' : 'bx bxs-info-circle'}`"></i></button>
    </div>
    <!-- инфо-блоки -->
    <infoblocks v-if="tours[100] ? true : infoblockShow" :obj="this.information" style="margin-top:15px;" />

    <!-- компонент таблицы 
      - відображення таблиці по параметру this.showTable
      - :key - ключик для оновлення компоненту
      - :dataParams - дані-інфа про сторінку на котрій таблиця запускається
      - :objParams - дані для фільтрів
      - :columns - колонки для таблички  
      - :rows - масив данних для таблиці 
      - :createdform - дозвіл на створення елементу
      - :removeitem - дозвіл на видалення елементу
      - :tabs - таби
      - :addtabs - дозвіл на додавання табу
      - :checks - виділенні рядки
      - :data - дата та час
      - @check - подія виділення рядку
      - @search - подія пошуку  
      - @changeFilter - подія на зміну фільтру 
      - @getdata - подія на оновлення даних
      - @changeTab - подія по зміні табу
      - @addCategory - подія додавання табу
      - @open - подія відкриття картки елементу
      - @create - подія створення нового елементу
    -->
    <tablecustom
      v-if="this.showTable === true"
      :key="this.componentKey"
      :dataParams="dataParams"
      :objParams="objParams"
      :columns="columns" 
      :rows="rows" 
      :createdform="this.perms[101] || this.perms[2000]"
      :removeitem="this.perms[103] || this.perms[2000]"
      :tabs="othertabs"
      :addtabs="true"
      :checks = "this.checkrows"
      :data="this.date"
      @check="select"
      @search="searchB"  
      @changeFilter="changeFilter" 
      @getdata="getdata"
      @changeTab="changeTab"
      @addCategory="showFilterBox = true"
      @open="open"
      @create="create"
    ></tablecustom>


    <!-- картка заявки 
      - відкриття відбувається через цикл
      - @close - подія закриття модалки (картки)
      - :shortapplic - пропс, що несе в собі інформацію. 
      - @getdata - оновлення загальних даних
      - @edit - подія запиту на редагування
      - @remove - подія запиту на видалення
      - :ifPrevopen - id заявки, що треба відкрити, якщо натиснути стрілочку "назад"
      - :ifNextopen - id заявки, що треба відкрити, якщо натиснути стрілочку "вперед"
      @prevOpen - подія відкриття попередньої заявки 
      @nextOpen - подія відкриття наступної заявки
      @openUnregistered - подія відкриття незареєстрованого клієнта
      @openRegistered - подія відкриття зареєстрованого клієнта
    -->
    <viewapplic 
      v-for="view in viewForms"
      @close="closeView" 
      :shortapplic="cardApplic" 
      @getdata="getdata"
      @edit = 'edit'
      @remove="remove"     
      :ifPrevopen="ifPrevopen" 
      :ifNextopen="ifNextopen" 
      @prevOpen="prevOpen" 
      @nextOpen="nextOpen"
      @openUnregistered = "openUnregistered"
      @openRegistered = "openRegistered"
    >
    </viewapplic>

    <!-- создание заявки 
      - відкриття модалки по параметру modalShow 
      - :obj - дані про заявку
      - :perm - дозвіл на зміну
      - @open - подія відкриття заявки
      - @closeLast - подія закриття картки заявки
      - @close - подія закриття створення
    -->
    <createapplicBox 
      v-if="modalShow" 
      :obj="cardApplic"
      :perm="this.permEdit" 
      @open = "openRequest"
      @closeLast = "closeLast"
      @close = "this.modalShow = false; this.permEdit = false"
    ></createapplicBox>

    <!-- табы 
      - відкриття модалки по параметру showFilterBox 
      - @close - подія закриття
      - @changeTabs - подія зміни табу
      - :othertabs - пропс з дефолтними табами
    -->
    <filterTabs 
      v-if="showFilterBox" 
      @close="showFilterBox = false"
      @changeTabs="changeTabs"
      :othertabs="othertabs"
    >
    </filterTabs>

    <!-- подивитися на незареєстрованні 
      - відкриття модалки по параметру showViewUnRegist
      - objCard - картка клієнта
      - @close - подія закриття
      - @call - подія початку дзвінку 
    -->
    <viewUnRegist
      v-if="showViewUnRegist == true"
      :objCard="this.objClient"
      @close = "showViewUnRegist = false"
      @call = "call"
    ></viewUnRegist>
    <!-- подивитися на незареєстрованні -->

    
    <!-- модалка картки клієнта 
      - відкриття модалки по параметру testshowModalclient
      - @close - подія закриття
      - objCard - картка клієнта
      - form_applic - дані по заявці
      - @call - подія початку дзвінку 
    -->
  <viewRegist 
    v-if="testshowModalclient == true" 
    @close="testshowModalclient = false" 
    :objcard="this.objClient"
    :form_applic="this.shortapplic"
    @call = "call"
  ></viewRegist>
    <!-- модалка картки клієнта -->

    <!-- модалка, що пропонує взяти заявку в роботу
      - відкриття модалки по параметру modalInWork
      - @close - закриття модалки
      - @takeInWork - взяття заявки в роботу
      - @open - відкриття заявки
      - :id - id заявки обраного клієнта
      - :phone - номер телефону обраного клієнта
    -->
  <workModal
    v-if="this.modalInWork === true"
    @close="this.modalInWork = close"
    @takeInWork="inJob"
    @open="openRequest"
    :id="this.currentClient.id"
    :phone="this.currentClient.phone"
  >

  </workModal>

</template>

<script>
import workModal from './table/workModal'
import {getMonth} from '@/usabilityScripts/globalMutate.js'
import viewRegist from '@/views/accounts/allAccounts/viewnew/index'
import viewUnRegist from '@/views/accounts/unregistered/view/index'
import PageHeader from "@/components/page-header";
import filterTabs from './filterTabs/index.vue'
import infoblocks from '@/components/info/blocks'
import tablecustom from './table/table.vue'
import createapplicBox from '../application/createapplic.vue'
import dialogBox from '@/components/modal/dialog'
import { Applications } from '@/API.js';
import viewapplic from '../application/view/viewapplic'
import { storeS } from "../../store";
import { mutatePriority, mutateStatus, mutateTypeRequest, mutateTypeHistoryAcc } from '@/usabilityScripts/globalMutate.js'
import { nameWorker } from '@/usabilityScripts/getNameWorker'
import { sendTelegramMessage } from '@/usabilityScripts/logsTelegram.js' // надсилання логу в телеграм

let apiServe = new Applications();  

export default {
  components: {
    workModal,
    viewRegist,
    viewUnRegist,
    PageHeader,
    viewapplic,
    createapplicBox,
    tablecustom,
    infoblocks,
    dialogBox,
    filterTabs,
  },
  data() {
    return {
      currentClient: {
        phone: '',
        id: ''
      }, // інфа по обраному клієнту
      showTable: false, // показати таблицю
      removeitem: true, // дозвіл на видалення
      allSelected: false, // чи всі вибрані рядки
      checkrows: [], // вибрані рядки
      componentKey: 0, // ключ
      filterCount: 0, // заглушка проти повторів одного й того ж івенту
      tour: null, // тур
      viewForms: 0, // рахунок форм
      form: '', // форма
      testshowModalclient: false, // відкриття картки клієнта
      objClient: '', // об'єкт з карткою клієнта
      showViewUnRegist: false, // покаати незареєстрованого
      title: "calls_all", // назва сторынки
      modalShow: false, // показати модальне вікно
      infoblockShow: false, // показати інфо-блоки
      showFilterBox: false, // показати фільтри
      colors:[ // кольори
        {
          title: this.$t('color_green'),
          value: "success"
        },
        {
          title: this.$t('color_orange'),
          value: "warning"
        },
        {
          title: this.$t('color_blue'),
          value: "info"
        },
        {
          title: this.$t('color_dark_blue'),
          value: "primary"
        },
        {
          title: this.$t('color_red'),
          value: "danger"
        }
      ],
      date: '', // дата
      dataParams: { // параметри сторінки
          status: true,
          page: "calls"
      },
      objParams:{ // дані про фільтри, тощо
        page: '1',
        pagelimit: '10000',
        search: '',
        status: '',
        priority: '',
        workerId: '',
        inJobWorkerId: '',
        pr1: '',
        pr2: '',
        actionCall: '',
      },
      columns: [ // колонки
        {
          name: 'ID',
          text: "requestId",
          align: "left",
          status: false,
        },
        {
          name: this.$t('callType'),
          text: "action",
          align: "left",
          status: true,
          mutate: (item) => this.mutateType(item).name,
          mutateClass: (item) => this.mutateType(item).color
        },
        {
          name: this.$t('Application_type'),
          text: "type",
          align: "left",
          status: true,
          mutate: (item) => this.mutateTypeApplic(item).name
        },
        {
          name: this.$t("phone_num"),
          text: "userPhone",
          align: "right",
          status: true,
        },
        {
          name: this.$t("Status"),
          text: 'status',
          align: "left",
          status: true,
          mutate: (item, obj) => this.mutateStatus(item, obj).name,
          mutateClass: (item, obj) => this.mutateStatus(item, obj).color
        },
        {
          name: this.$t("priority"),
          text: "priority",
          align: "right",
          status: true,
          mutate: (item) => this.mutatePriority(item).name,
          mutateClass: (item) => this.mutatePriority(item).bg
        },
        {
          name: this.$t("date_create"),
          text: "createDatetime",
          align: "right",
          status: true,
        },
        {
          name: this.$t("added"),
          text: "createWorkerId",
          align: "right",
          status: true,
          mutate: (item) => item ? this.nameWorker(item) : this.checks['024'] ? 'Jesus' : '-' 
        },
      ],
      modalInWork: false, // параметр показу модалки
      rows: [], // дані
      interval: '', // інтервал
      permEdit: false, // дозвіл на редагуваання
      cardApplic: {}, // пропс для заявки
      ifNextopen: "", // для id наступної заявки
      ifPrevopen: "", // для id попередньої заявки
      information: [ // інформація
        {
            title: this.$t('Total'),
            value: "0",
            icon: "las la-file-invoice",
            color: "info"
        },
        {
            title: this.$t('new'),
            value: "0",
            icon: "las la-file-invoice",
            color: "success"
        },
        {
            title: this.$t('statusInWork'),
            value: "0",
            icon: "las la-file-invoice",
            color: "warning"
        },
        {
            title: this.$t('missed'),
            value: "0",
            icon: "las la-file-invoice",
            color: "danger"
        }
      ],
      othertabs: [], // інші таби
      checked: false, // чи виділено
      counter: 0, //лічильник секцій в таблиці (всі дзвінки треба поділити на 100)
    };
  },
  beforeUnmount(){
    clearTimeout(this.interval) // очистити зайвий таймаут
    if(sessionStorage.getItem('pageInCall')) {
      sessionStorage.removeItem('pageInCall')
    } // якщо ми виходимо з вкладки, то видаляємо з пам'яті сторінку в таблиці
  },
  created() {
    // дата для календарю
    if(!this.date) {
      this.date = {
        title: this.currentDate().day + ' ' + this.$t(`${this.currentDate().month}`) + ', ' +this.currentDate().year,
        value: this.currentDate().year + '-' + new Date().getMonth() + this.currentDate().day,
        month: this.currentDate().monthNum
      }
    }

    this.getdata() // отримання даних

  },
  methods: {
    inJob(id, phone) { // взяти в роботу заявку і отримати потім картку клієнта
      apiServe.addRequestInJob(id).then(result => {
        if(result.status === 'done') {
          this.modalInWork = false
          this.eventBus.emit('getClient', {status: true, number: phone, requestId: id})
        } else {
          this.$toast.error(this.$t('error') + " " + result.error[0]);

          var errorText = 'Помилка взяття заявки в роботу'; 
          var statusRequest = result.status;
          var api = 'addRequestInJob';
          var fileRequest = 'src/views/calls/all.vue';
          var params = id;
          var response = result

          // Викликаємо функцію для відправки повідомлення в телеграм
          sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
          .then(response => console.log('Telegram API response:', response.data))
          .catch(error => console.error('Telegram API error:', error));
        }
      })
    },
    month(e) { // взяти актуальний місяць
      return getMonth(e)
    },
    currentDate() { // дізнатися актуальну дату
      var date = new Date();
      var dateStr = {
        day: ("00" + (date.getDate())).slice(-2),
        month: (this.month(date.getMonth() + 1)),
        year: date.getFullYear(),
        monthNum: date.getMonth()
      }
      return dateStr
    },
    call(e) { // оброблення актуального дзвінка
      localStorage.setItem('actualCall', e)
      this.eventBus.emit('actualCall', {
        status: true
      })
    },
    closeView() { // закрити перегляд та оновити дані
      this.viewForms = 0
      this.getdata(this.objParams.page)
    },
    closeLast() { // закрити минулу заявку
      this.viewForms--
    },
    openRequest(id){ // відкрити заявку після створення
      // якщо прийшов id, то робимо додатковий запит
      console.log(typeof id)
      if(typeof id == 'number') {
        this.modalInWork = false
        apiServe.getRequest(id).then(result => {
          if(result.status == 'done') {
            this.cardApplic = result.data
            this.modalInWork = false
            this.viewForms++
          } else {
            this.$toast.error(this.$t('error'));

            var errorText = 'Помилка отримання заявки'; 
            var statusRequest = result.status;
            var api = 'getRequest';
            var fileRequest = 'src/views/calls/all.vue';
            var params = e;
            var response = result

            // Викликаємо функцію для відправки повідомлення в телеграм
            sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
            .then(response => console.log('Telegram API response:', response.data))
            .catch(error => console.error('Telegram API error:', error));
          }
        })
      } else {
        this.cardApplic = id
      }
      this.modalShow = false;
      this.viewForms++
    },
    create() { // подія створення
      this.permEdit = false
      this.modalShow = true
    },
    getdata(page){ // отримати дані
      apiServe.getRequests(this.objParams).then(result => {
        if(result.status === 'done') {
          this.rows = result.data.items;
          if(this.showTable === false) {
            this.showTable = true
          } else {
            this.componentKey++
          }
          this.information[0].value = result.data.total
          this.information[1].value = result.data.countNew
          this.information[2].value = result.data.countInJob
          this.information[3].value = result.data.countMissing

          // впав сокет на зміну статусу на "Подзвонити зараз", костиль, юзати тільки на локалці

          if(this.user.userid === 46) {
            let obj = {
              requestIds: []
            }

            this.rows.forEach(el => {
              if(el.status == "delayed" && new Date(el.delayedDatetime) < new Date()) {
                obj.requestIds.push(el.requestId)
              }
            })
            
            if(obj.requestIds.length > 0) {
              apiServe.packCallNow(obj).then(result=> {
                if(result.status == 'done') {
                  this.$toast.success(this.$t('success'))
                }
              })
            }
          }

          // впав сокет на зміну статусу на "Подзвонити зараз", костиль, юзати тільки на локалці
        } else {
          this.$toast.error(this.$t('error') + ` #100`);

          var errorText = 'Помилка отримання заявок'; 
          var statusRequest = result.status;
          var api = 'getRequests';
          var fileRequest = 'src/views/calls/all.vue';
          var params = this.objParams;
          var response = result

          // Викликаємо функцію для відправки повідомлення в телеграм
          sendTelegramMessage(errorText, statusRequest, api, fileRequest, params, response)
          .then(response => console.log('Telegram API response:', response.data))
          .catch(error => console.error('Telegram API error:', error));
        }
      })
    },
    open: function(e, withoutCheck){ // відкриття заявки
      var id = e.requestId ? e.requestId : e;
      this.ifPrevopen = id == this.rows[0].requestId ? false : true; // перевіряємо чи можна відкррити попередню заявку
      this.ifNextopen = id == this.rows[this.rows.length-1].requestId ? false : true; // перевіряємо чи можна відкрити наступну заявку

      // Якщо заявка не має параметру, що відкривається без чеку та не є в роботі, то ми відкриваємо підтвердження
      if(!withoutCheck && e.status !== 'jobs' && e.status !== 'wait_comment'){ 
        this.currentClient.id = e.requestId // запам'ятовуємо айді заявки
        this.currentClient.phone = e.userPhone // запам'ятовуємо номер клієнта
        this.modalInWork = true // від криваємо заявку на взяття в роботу
      } else {
        // this.cardApplic = e
        this.eventBus.emit('getClient', {status: true, number: e.userPhone, requestId: e.requestId})
      }
      
    },
    prevOpen(e){ // дія відкриття попередньої заявки
      var current_id = e;
      var prev_id = ""; 

      for(var value in this.rows){ // дивимося дані та шукаємо попередню заявку
        if(this.rows[value].requestId == current_id){
          prev_id = this.rows[Number(value)-Number('1')].requestId;
        }
      }
      this.open(prev_id, true); // кидаємо запит
    },
    nextOpen(e){ // для відкриття наступної заявки
      var current_id = e;
      var next_id = "";

      for(var value in this.rows){
        if(this.rows[value].requestId == current_id){
          next_id = this.rows[Number(value)+Number('1')].requestId;
        }
      }
      this.open(next_id);
    },
    searchB(e){ // функція пошуку
      this.objParams.search = e
      this.getdata();
    },
    changeTab(name, value){ // зміна табу
      var arrFilter = ['search','actionCall', 'status', 'priority', 'type', 'workerId', 'inJobWorkerId'];
      for(var item in arrFilter){
        if(arrFilter[item] == name){
          this.objParams[name] = value
        } else {
          if(name == 'pr1') {
            this.objParams.actionCall = ''
            this.objParams.search = ''
            this.objParams.priority = ''
            this.objParams.status = ''
            this.objParams.workerId = ''
            this.objParams.inJobWorkerId = ''
            let today = String(new Date()).split(' ')
            if(value == 'today'){
              this.objParams.pr1 = today[3]+'-'+this.mounthMutate(today[1])+'-'+today[2]
              this.objParams.pr2 = today[3]+'-'+this.mounthMutate(today[1])+'-'+String(Number(today[2])+1)
              this.getdata()
            } else if(value == 'yesterday'){
              this.objParams.pr1 = today[3]+'-'+this.mounthMutate(today[1])+'-'+String(Number(today[2])-1)
              this.objParams.pr2 = today[3]+'-'+this.mounthMutate(today[1])+'-'+String(Number(today[2]))
              this.getdata()
            }
            return
          }
          this.objParams[arrFilter[item]] = '';
        }
      }
      this.getdata();
    },
    mounthMutate(e) { // мутейт місяця
      var c = ''
      switch (e) {
        case 'Feb':
          c = '2'
        break;
        case 'Mar':
          c = '3'
        break;
        case 'Apr':
          c = '4'
        break;
        case 'May':
          c = '5'
        break;
        case 'Jun':
          c = '6'
        break;
        case 'Jul':
          c = '7'
        break;
        case 'Aug':
          c = '8'
        break;
        case 'Sep':
          c = '9'
        break;
        case 'Oct':
          c = '10'
        break;
        case 'Nov':
          c = '11'
        break;
        case 'Dec':
          c = '12'
        break;
        case 'Jan':
          c = '1'
        break;
      }
      return c
    },
    changeFilter(name, value){ // зміна фільтру
      console.log(name, value)
      if(this.filterCount < 1) {
        this.filterCount++
        if(name == 'all'){
          this.objParams.actionCall = '',
          this.objParams.search = '',
          this.objParams.priority = '',
          this.objParams.status = '',
          this.objParams.workerId = '',
          this.objParams.inJobWorkerId = '',
          this.objParams.pr1 = '',
          this.objParams.pr2 = '';
          this.getdata();
        } else if (name == 'pr') {
          if(value[0] === '') {
            return
          }
          this.objParams.pr1 = value[0],
          this.objParams.pr2 = value[1];
          if(value[0] == value[1]) {
            this.date = {
              title: value[0].split('-')[2] + ' ' + this.$t(this.month(value[0].split('-')[1])) + ' ' + value[0].split('-')[0],
              value: value[0].split('-')[2] + ' ' + value[0].split('-')[1] + ' ' + value[0].split('-')[0]
            }
          } else {
            this.date = {
              title: value[0].split('-')[2] + ' ' + this.$t(this.month(value[0].split('-')[1])) + ' ' + value[0].split('-')[0] + ' - ' + value[1].split('-')[2] + ' ' + this.$t(this.month(value[1].split('-')[1])) + ' ' + value[1].split('-')[0],
              value: value[0].split('-')[2] + ' ' + value[0].split('-')[1] + ' ' + value[0].split('-')[0] + ' - ' + value[1].split('-')[2] + ' ' + value[1].split('-')[1] + ' ' + value[1].split('-')[0]
            }
          }
          this.getdata()
        } else {
          this.objParams[name] = value
          this.getdata()
        }
        this.filterCount = 0
      } else {
        this.filterCount = 0
      }
    },
    openUnregistered(client, modal) { // відкрити картку незареєстрованого клієнта
      this.showViewUnRegist = true
      this.objClient = client
    },
    
    openRegistered(client, modal) { // відкрити картку зареєстрованого клієнта
      this.testshowModalclient = true
      this.objClient = client
    },
    changeTabs(item){ // змінити таб
      for(var item in this.items){
        this.othertabs.push(this.items[item])
      }
      // this.othertabs.push(item);
      localStorage.setItem("Callscheckbox", JSON.stringify(this.othertabs));
      localStorage.setItem("resultCalls", this.othertabs.length)
      this.getdata()
    },
    nameWorker(item){ // мутейт ім'я співробітника
      return nameWorker(item)
    },
    mutateStatus(e, obj){
      return mutateStatus(e, obj); // мутейт статусу
    },
    mutatePriority(e){
      return mutatePriority(e); // мутейт пріоритету
    },
    mutateType(e){
      return mutateTypeRequest(e) // мутейт типу дзвінка
    },
    mutateTypeApplic(e){
      return mutateTypeHistoryAcc(e) // мутейт типу заявки
    },
    select(e,a) { // виділити заявку
      this.allSelected = a
      this.checkrows = e
    },
    clearFilter(){ // очистити фільтр
      this.selectApplication_type = "0";
      this.selectpriority = "0";
      this.selectuser = "0";
      this.selectstatus = "0";
    },
    edit(e) { // запит на зміну заявки
      this.permEdit = true;
      this.modalShow = true;
    },
    createTourAdd(){ // свторити тур
      this.tour = this.$shepherd({
          useModalOverlay: true
      });
      const stepAdd1 = document.getElementById('stepAdd1');
      this.tour.addStep({
          attachTo: { element: stepAdd1, on: 'bottom' },
          text: 'Вітаємо на сторінці "Усі дзвінки". На сторінці ви можете переглянути загальну інформацію та інші дані.',
          buttons: [
              {
                  action: this.tour.next,
                  text: this.$t('Next')
              }
          ],
      });

      const stepAddDoc = document.getElementById('stepAddDoc');
      this.tour.addStep({
          id: stepAddDoc,
          text: 'У цьому блоці відображається загальна кількість заявок',
          attachTo: {
              element: stepAddDoc,
              on: 'bottom'
          },
          buttons: [
              {
                  action() {
                      return this.back();
                  },
                  classes: 'shepherd-button-secondary',
                  text: this.$t('back')
              },
              {
                action: this.tour.next,
                // action(){
                //   return this.modalShow = true
                // },
                text: this.$t('Open')
              },
          ]
      });
      
    },
    createTour(){ // створити тур (?)
      this.tour = this.$shepherd({
          useModalOverlay: true
      });
      const info = document.getElementById('info');
      this.tour.addStep({
          attachTo: { element: info, on: 'top' },
          text: 'Вітаємо на сторінці "Усі дзвінки". На сторінці ви можете переглянути загальну інформацію та інші дані.',
          buttons: [
              {
                  action: this.tour.next,
                  text: this.$t('Next')
              }
          ],
      });
      const infoStep0 = document.getElementById('infoStep0');
      this.tour.addStep({
          id: infoStep0,
          text: 'У цьому блоці відображається загальна кількість заявок',
          attachTo: {
              element: infoStep0,
              on: 'bottom'
          },
          buttons: [
              {
                  action() {
                      return this.back();
                  },
                  classes: 'shepherd-button-secondary',
                  text: this.$t('back')
              },
              {
                action: this.tour.next,
                text: this.$t('Next')
              },
          ]
      });

      const infoStep1 = document.getElementById('infoStep1');
      this.tour.addStep({
          id: infoStep1,
          text: 'У цьому блоці відображається загальна кількість нових заявок',
          attachTo: {
              element: infoStep1,
              on: 'bottom'
          },
          buttons: [
              {
                action() {
                    return this.back();
                },
                classes: 'shepherd-button-secondary',
                text: this.$t('back')
              },
              {
                action: this.tour.next,
                text: this.$t('Next')
              },
          ]
      });

      const infoStep2 = document.getElementById('infoStep2');
      this.tour.addStep({
          id: infoStep2,
          text: 'У цьому блоці відображається загальна кількість заявок в роботі, які виконує менеджер',
          attachTo: {
              element: infoStep2,
              on: 'bottom'
          },
          buttons: [
              {
                action() {
                    return this.back();
                },
                classes: 'shepherd-button-secondary',
                text: this.$t('back')
              },
              {
                action: this.tour.next,
                text: this.$t('Next')
              },
          ]
      });

      const infoStep3 = document.getElementById('infoStep3');
      this.tour.addStep({
          id: infoStep3,
          text: 'У цьому блоці відображається загальна кількість пропущених заявок. Якщо є питання, зверніться до Бориса, або поверніться до кроку 1.',
          attachTo: {
              element: infoStep3,
              on: 'bottom'
          },
          buttons: [
              {
                action() {
                    return this.back();
                },
                classes: 'shepherd-button-secondary',
                text: this.$t('back')
              },
              {
                action: this.tour.next,
                text: this.$t('Next')
              },
          ]
      });

      // таблиця
      const step1 = document.getElementById('step1');
      this.tour.addStep({
          id: step1,
          text: 'У цьому полі ви можете змінити ліміт відображений даних, скористатись пошуком, датою та фільтром. Та додати заявку.',
          attachTo: {
              element: step1,
              on: 'bottom'
          },
          buttons: [
              {
                action() {
                    return this.back();
                },
                classes: 'shepherd-button-secondary',
                text: this.$t('back')
              },
              {
                action() {
                    return this.complete();
                },
                classes: 'shepherd-button-secondary',
                text: this.$t('toComplete')
              },
          ]
      });
    },
  },
  computed: {
    user() {
      return storeS.userbase
    },
    perms() {
      return storeS.perms
    },
    checks() {
      return storeS.checks
    },
    workers() {
      return storeS.workers
    },
    tours() {
      return storeS.tours
    },
  },
  mounted() {

    // гайд
    if(this.tours[100]){
      this.createTour();
      this.tour.start();
    } else if(this.tours[101]){
      this.createTourAdd();
      this.tour.start();
    }
    // гайд
    this.othertabs = localStorage.getItem("Callscheckbox") ? JSON.parse(localStorage.getItem("Callscheckbox")) : [];

    this.eventB.off('updateRequestByCall')
    // оновити дані з сокєту
    this.eventB.on('updateRequestByCall', (status) => {
      this.counter++ // лічильник
      console.log('counter increased to ', this.counter);
      if(this.counter == 1) { // ловимо перші запити і ігноруємо всі інші, до обнулення лічильника
        console.log('counter is 1');
        this.interval = setTimeout(() => {
          this.counter = 0;
          console.log('counter has been 0')
          this.getdata();
        }, 3000)
      }
    })
  }
};
</script>

<style scoped>
.tr_etner:hover {
  cursor: pointer;
  background: #f3f3f9;
}
.howto_block {
  margin-left:10px;
}
.howto_block i {
  font-size: 23px;
  opacity: 0.5;
}
.howto_block i:hover{
  opacity: 1;
}

.howto_block {
  position: relative;
  display: inline-block;
}

.howto_block .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  padding: 10px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  margin-left: 10px;
}

.howto_block .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -7px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #555 transparent transparent;
}

.howto_block:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.mainCalendarButton{
  height: 30px;
  border: none;
  border-radius: 0.25rem;
  background-color: #eff2f7;
  box-shadow: 0 1px 2px rgba(56, 65, 74, 0.15) !important;
  font-size: 0.8125rem;
  font-weight: 400;
  line-height: 1.5;
  width: 200px;
}
[data-layout-mode="dark"] .mainCalendarButton {
  background-color: #1A1D1E !important;
  color: white
}
</style>
